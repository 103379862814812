.offline-modal {
  & .d-progress-spinner {
    overflow: visible;
  }

  & .progress-spinner {
    border-left-color: var(--color-warning-dark);
  }

  & .cancel {
    display: none;
  }

  & .d-modal--form {
    border-left: 8px solid var(--color-warning);
  }

  & .d-modal--header,
  & .d-modal--title {
    color: var(--color-warning-dark);
  }
}
