@import 'ui/responsive-variables.css';

.d-offline-notification {
  justify-content: space-between;
  overflow: hidden;
  transition: all 200ms;
  transition-timing-function: ease-in-out;

  &.d-alert.d-alert-small {
    font-size: var(--heading-font-size);
    line-height: var(--heading-line-height);
    margin-bottom: 0;
  }

  & .title {
    text-transform: uppercase;
  }

  &.online {
    height: 0;
    padding: 0 8px;

    & .progress-spinner {
      animation: none;
    }
  }

  & .d-progress-spinner {
    flex-direction: row-reverse;
  }

  & .progress-spinner {
    border-left-color: var(--alert-color-warning);
  }
}
